<template>
  <div class="privacy a-fs-14 a-c-666" v-html="service.service"></div>
</template>

<script>
import service from "../../../utils/service";
export default {
  data() {
    return {
      brandCompany: "玖电科技",
      brandName: "毛豆充",
      service: service,
    };
  },
};
</script>

<style lang="scss" scoped>
.privacy {
  background: #fff;
  padding: 12px 0;
  &-box {
    margin-top: 30px;
    &-title {
      padding-bottom: 12px;
    }
  }
}
</style>
