export default {
  service: `<div><span class='a-fs-20 a-fw-700'>充电用户协议</span><br /><br />

    我们在此特别提醒用户（您）在注册成为用户之前，请用户（您）认真阅读本《用户协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。您的浏览、注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。本协议约定雅迪集团（以下简称“雅迪商户助手”）与用户之间有关于“雅迪商户助手”软件服务（以下简称“服务”）中的权利和义务。 “用户”是指浏览、注册、登录、使用本服务的个人。
    本协议可由雅迪集团根据服务内容及市场情况随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在软件中查阅最新版协议条款。在修改协议条款后，如果用户不接受修改后的条款，请用户立即停止使用雅迪商户助手提供的服务，用户继续使用服务则将被视为接受修改后的协议。<br /><br />
    
    <span class='a-fs-16 a-fw-700'>一、用户的登记/注册/绑定</span><br /><br />
    
    1、用户在使用本服务前需要登录、注册或绑定一个“雅迪商户助手”账号。“雅迪商户助手” 用户、运营商应当使用手机号码和验证码绑定注册，可根据本协议进行登录、注册或绑定“雅迪商户助手”账号的手机号码。“雅迪商户助手”所提供的服务可以根据用户需求或产品需要对账号注册和绑定的方式进行变更、升级或调整，而无须事先告知用户。<br />
    2、“雅迪商户助手账号”系基于“雅迪商户助手“的产品，用户绑定时应当授权雅迪商户助手及使用其个人信息身份证信息方可成功注册“雅迪商户助手”账号。故用户完成登录、注册或绑定即表明用户同意服务提取、公开及使用用户的信息。<br />
    3、鉴于“雅迪商户助手”账号的绑定注册方式，我们将通过发送短信的方式告知并验证您的身份是否有效，您可以根据收到的短信验证码进行登录使用我们的产品和服务。<br />
    4、在用户注册及使用本服务时，雅迪商户助手需要搜集能识别用户身份的个人信息以便对用户提供服务，或可以在必要时联系用户，或为用户提供更好的使用体验。您提供的个人信息可以被本公司通过雅迪商户助手查看，作为与您联系的方式。雅迪商户助手搜集的信息包括但不限于用户的姓名、头像照片、证件信息、手机号码、地址等个人资料；雅迪商户助手同意对这些信息的使用将受限于用户个人隐私信息保护的约束。<br />
    5、用户（您）登录之后可以完善用户的个人资料，用户一旦选择、编辑、填写了用户的信息，即视为用户已同意提供，请您谨慎提供。用户（您）可以完善的个人信息包括更换头像、更改用户名，更改手机号等。当您登记您的子女及其他亲属的身份信息，包括孩子资料中的头像、头像照片、证件信息、手机号码、银行卡号、地址等。<br /><br />
    
    <span class='a-fs-16 a-fw-700'>二、我们如何使用用户的信息</span><br /><br />
    
    收集用户信息的目的在于更好地向您提供我们的产品和服务，我们将您的用户信息用于以下目的：<br />
    1、产品服务。提供、处理、维护、改善、开发我们的产品和提供给您我们的服务，包括客户支持，以及通过设备或产品提供的其他服务。<br />
    2、创建用户（您）的账号信息。在通过我们的产品或服务创建账号时收集的个人信息用于建立用户的个人账户和资料页。<br />
    3、方便您使用社区论坛及其他网络互动。与社区平台有关的个人信息可能被用于个人资料页面展示、与其他用户互动、发布帖子。<br />
    4、统计分析。分析和开发与我们产品及服务的使用相关的统计信息，以更好地改进我们的产品和服务。<br />
    5、改善用户体验。分析用户如何使用我们的产品和服务的数据，使我们更加了解您的使用习惯和偏好等，从而针对性地回应您的个性化需求。<br />
    6、验证用户身份。账号登录时短信验证，刷卡或刷脸通过设施时，核验比对，确保未经授权的人员无法登录或通过设施；依法进行真实用户认证，以符合法律要求。<br />
    7、收集用户反馈。您的反馈意见对我们产品和服务的改进和提升有极大的帮助，为了跟踪您的反馈，我们可能会使用您提供的个人信息与您取得联系。<br /><br />
    
    <span class='a-fs-16 a-fw-700'>三、用户违反协议的相关处罚措施</span><br /><br />
    
    1、如果雅迪商户助手发现或收到他人举报或投诉用户违反本协议约定的，雅迪商户助手有权不经通知随时对相关内容，包括但不限于用户资料、发贴记录进行审查、删除，并视情节轻重对违规账号处以包括但不限于警告、账号封禁 、设备封禁 、功能封禁 的处罚，且通知用户处理结果。<br />
    2、因违反用户协议被封禁的用户，可以自行与雅迪商户助手客服联系。其中，被实施功能封禁的用户会在封禁期届满后自动恢复被封禁功能。被封禁用户可提交申诉，雅迪商户助手将对申诉进行审查，并自行合理判断决定是否变更处罚措施。<br />
    3、用户理解并同意，雅迪商户助手有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。<br />
    4、用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿雅迪商户助手与合作公司、关联公司，并使之免受损害。<br /><br />
    
    <span class='a-fs-16 a-fw-700'>四、信息分享与披露</span><br /><br />
    
    “雅迪商户助手”不会在未经授权的情况下将任何个人信息销售或转让给第三方。除非下列情形发生时，我们无权向第三方共享或披露您的全部或部分个人信息：<br />
    （一）为了维护国家安全和重大公共利益；<br />
    （二）为保护他人的人身或财产安全；<br />
    （三）为防止或解决网络欺诈；<br />
    （四）维护我们的合法权益；<br />
    （五）根据法律、法规，或遵从政府部门、司法机关的要求；通常前述要求都是为了保护网络安全和保护我们、我们的用户和第三方的权利、财产和安全。<br />
    （六）根据教育主管部门要求，并取得用户信息提供方同意，在教育主管部门行政管辖区域内，有条件地提供相关信息。<br />
    （七）与授权合作伙伴共享：我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职能，我们仅会出于本隐私权政策声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的。<br /><br />
    
    <span class='a-fs-16 a-fw-700'>五、您如何管理您的信息</span><br /><br />
    
    （一）查询、更正和补充您的信息<br />
    您有权查询、更正或补充您的信息。您可以通过以下方式进行：<br />
    1.进入“我的-绑定”对个人资料信息进行查询、更正或补充。<br />
    2.如果您希望撤销同意手机、使用披露我们收集的您的个人信息或者希望我们删除您的个人信息，可联系我们的客服人员。我们将在收到您的联系电话、电子邮箱或信函后的合理时间内处理您的请求，并且会根据您的请求，在此之后不再收集、使用、披露您的个人信息。请注意，您撤销同意或者删除您的个人信息后，可能会影响您使用某些我们的产品或服务。<br />
    （二）删除您的信息<br />
    在以下情形中，您可以向我们提出删除个人信息的请求：<br />
    1、如果我们处理个人信息的行为违反相关的法律法规；<br />
    2、如果我们收集、使用您的个人信息，却未征得您的明确同意；<br />
    3、如果我们处理个人信息的行为严重违反了与您的约定；<br />
    4、如果您决定不再使用我们的产品或服务，或您主动注销账号；<br />
    5、如果我们决定关闭账户不再为您提供产品或服务。<br />
    （三）注销您的账户<br />
    您可以通过联系客服提出账户注销的申请，在您主动注销了账户之后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息，或使其匿名化处理。<br /><br />
    
    <span class='a-fs-16 a-fw-700'>六、用户注意事项</span><br /><br />
    
    1、为了向您提供有效的服务，本软件会利用您移动通讯终端的处理器和带宽，定位等资源。本软件适用过程中可能产生数据流量或信息服务等的费用，用户需自行向运营商了解相关资费信息，并自行承担相关费用。<br />
    2、本软件中可能包含软文、广告、营销活动等，这属于雅迪商户助手及其关联公司合理合法的商业模式，您同意在使用本软件过程中接收由雅迪商户助手及其关联公司或者其第三方合作伙伴发布的软文，广告或营销活动信息。雅迪商户助手及其关联公司依照法律的规定对信息发布履行相关义务后，您应当自行判断软文、广告或活动信息的真实性并为自己的判断行为负责，除法律明确规定外，您因依该软文、广告或营销信息进行的交易或因广告主提供的内容而遭受的损失或损害，雅迪商户助手及其关联公司不承担责任。<br />
    3、雅迪商户助手不对您在使用本服务中相关数据的删除或存储失败负责。如果您停止使用本服务或本服务被终止或取消，我们可以从服务器上永久地删除您的数据，并没有义务向您返还任何数据。<br />
    4、用户在使用本服务时，须自行承担如下来自雅迪商户助手等不可掌控的风险内容，包括但不限于：<br />
    4.1 由于不可抗拒因素可能引起的个人信息丢失、泄露等风险；<br />
    4.2 用户须选择与所安装手机相匹配的软件版本，否则，由于软件与手机型号不相匹配所导致的任何问题或损害，由用户自行承担；<br />
    4.3 用户在使用本软件访问第三方网站时，因第三方网站及相关内容所可能导致的风险，由用户自行承担；<br />
    4.4 由于无线网络信号不稳定、无线网络带宽小等原因，所引起的登录失败、资料同步不完整、页面打开速度慢、产品或服务不可正常使用等风险。<br /><br />
    
    <span class='a-fs-16 a-fw-700'>七、第三方产品和服务</span><br /><br />
    
    您在使用本软件时需要使用到第三方提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的用户协议，雅迪商户助手和第三方对可能出现的任何争议及损害在法律规定和约定的范围内各自承担责任。<br /><br />
    
    <span class='a-fs-16 a-fw-700'>八、用户发布内容规范</span><br /><br />
    
    1、本条所述内容是指用户使用服务的过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于账号头像、卡号，证照、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、回复或自动回复消息和相关链接页面，以及其他使用账号或本服务所产生的内容。<br />
    2、用户不得利用“雅迪商户助手”账号或本服务制作、上载、复制、发布、传播如下法律、法规和政策禁止的内容：<br />
    (1) 反对国家宪法所确定的基本原则的；<br />
    (2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br />
    (3) 损害国家荣誉和利益的；<br />
    (4) 煽动民族仇恨、民族歧视，破坏民族团结的；<br />
    (5) 破坏国家宗教政策，宣扬邪教和封建迷信的；<br />
    (6) 散布谣言，扰乱社会秩序，破坏社会稳定的；<br />
    (7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br />
    (8) 侮辱或者诽谤他人，侵害他人合法权益的；<br />
    (9) 含有法律、行政法规禁止的其他内容的信息。<br />
    3、用户不得利用“雅迪商户助手”账号或本服务制作、上载、复制、发布、传播如下干扰“服务”正常运营，以及侵犯其他用户或第三方合法权益的内容：<br />
    (1) 含有任何色情或不良性暗示的、不利于青少年健康成长的；<br />
    (2) 含有辱骂、恐吓、威胁内容的；<br />
    (3) 含有骚扰、垃圾广告、恶意信息、诱骗信息的；<br />
    (4) 涉及他人隐私、个人信息或资料的；<br />
    (5) 侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；<br />
    (6) 含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。<br /><br />
    
    <span class='a-fs-16 a-fw-700'>九、使用规则</span><br /><br />
    
    1、用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表雅迪商户助手公司的观点、立场或政策，雅迪商户助手公司对此不承担任何责任。<br />
    2、用户不得利用“雅迪商户助手”账号或本服务进行如下行为：<br />
    (1) 提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；<br />
    (2) 强制、诱导其他用户关注、点击链接页面或分享信息的；<br />
    (3) 虚构事实、隐瞒真相以误导、欺骗他人的；<br />
    (4) 利用技术手段批量建立虚假账号的；<br />
    (5) 利用“雅迪商户助手”账号或本服务从事任何违法犯罪活动的；<br />
    (6) 制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；<br />
    (7) 其他违反法律法规规定、侵犯其他用户合法权益、干扰“雅迪商户助手”正常运营或服务未明示授权的行为。<br />
    用户须对利用“雅迪商户助手”账号或本服务传送信息的真实性、合法性、无害性、准确性、有效性等全权负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与雅迪商户助手无关。如因此给雅迪商户助手或第三方造成损害的，用户应当依法予以赔偿。 如因此给雅迪商户助手或第三方造成损害的，用户应当依法予以赔偿。<br />
    雅迪商户助手提供的服务中可能包括广告，用户同意在使用过程中显示雅迪商户助手和第三方供应商、合作伙伴提供的广告。除法律法规明确规定外，用户应自行对依该广告信息进行的交易负责<br />
    对用户因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，雅迪商户助手不承担任何责任。<br /><br />
    
    <span class='a-fs-16 a-fw-700'>十、其他</span><br /><br />
    
    1、雅迪商户助手郑重提醒用户注意本协议中免除雅迪商户助手责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。<br />
    2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和雅迪商户助手之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交雅迪商户助手住所地有管辖权的人民法院管辖。<br />
    3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。<br />
    本《协议》版权由雅迪集团所有，雅迪商户助手公司保留一切对本《协议》解释的权利。<br />
    </div>`,
};
